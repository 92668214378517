import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import ReactModal from 'react-modal';

import 'components/modalWindow/scss/modal.scss';
import { GlobalStateContext } from 'containers/GlobalContextProvider';
import { generateUniqueId } from 'utilities';
import { AcademyApplyCard } from 'components/academyApplyCard';

const AcademyApplicationModal = ({
  academyLocations,
  academyTitle,
  academyType,
  applicationDeadline,
  applicationLinks,
  modalTitle,
  setModalState,
  status,
}) => {
  ReactModal.setAppElement('#___gatsby');

  const languageContext = useContext(GlobalStateContext);
  const translation = languageContext.dictionary.translations;

  return (
    <ReactModal
      className="modal modal--wide"
      isOpen={status}
      shouldCloseOnOverlayClick
      onRequestClose={() => setModalState(false)}
      aria={
        modalTitle && {
          labelledby: 'academy-application-modal__title',
        }
      }
    >
      <section className="academy-application-modal">
        <button
          className="modal__btn-close"
          type="button"
          onClick={() => setModalState(false)}
        >
          <span className="modal__btn-close-content">
            {translation['button.closeModal']}
          </span>
        </button>
        <h2 className="academy-application-modal__title">{modalTitle}</h2>
        <p className="academy-application-modal__subtitle">{academyTitle}</p>
        {academyLocations && academyLocations.length > 0 && (
          <div className="academy-application-modal__cards">
            {academyLocations.map((academyLocation) => (
              <AcademyApplyCard
                key={generateUniqueId()}
                academyLocation={academyLocation}
                applicationDeadline={applicationDeadline}
                academyType={academyType}
                applicationLink={
                  applicationLinks[academyLocation.toLowerCase()]
                }
              />
            ))}
          </div>
        )}
      </section>
    </ReactModal>
  );
};

AcademyApplicationModal.propTypes = {
  academyLocations: PropTypes.arrayOf(PropTypes.string.isRequired),
  academyTitle: PropTypes.string.isRequired,
  academyType: PropTypes.string.isRequired,
  applicationDeadline: PropTypes.string,
  applicationLinks: PropTypes.shape({
    kaunas: PropTypes.string.isRequired,
    vilnius: PropTypes.string.isRequired,
  }),
  modalTitle: PropTypes.string.isRequired,
  setModalState: PropTypes.func,
  status: PropTypes.bool,
};

export { AcademyApplicationModal };
