import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import generateUniqueId from 'utilities/generateUniqueId';
import { GlobalStateContext } from 'containers/GlobalContextProvider';

const LocationDropdown = ({
  selectedCity,
  dropdownRef,
  toggleChapterDropdown,
  openDropdown,
  setSelectedCity,
  academyLocations,
}) => {
  const languageContext = useContext(GlobalStateContext);
  const translation = languageContext.dictionary.translations;

  return (
    <div className="schedule-dropdown" ref={dropdownRef}>
      <button
        type="button"
        className="schedule-dropdown__button"
        onClick={toggleChapterDropdown}
        aria-expanded={openDropdown}
        aria-label={`${selectedCity}, ${translation['button.selectLocation']}`}
      >
        {selectedCity === 'Klaipeda'
          ? `${translation['academyInfo.label.city.klaipeda']}`
          : selectedCity}
      </button>
      {academyLocations && academyLocations.length > 1 && (
        <ul
          className={classNames('schedule-dropdown__list', {
            'open-dropdown': openDropdown,
          })}
        >
          {academyLocations.map((location) => (
            <li className="schedule-dropdown__item" key={generateUniqueId()}>
              <button
                className="schedule-dropdown__item-button"
                type="button"
                onClick={() => {
                  toggleChapterDropdown();
                  setSelectedCity(location);
                }}
              >
                {location === 'Klaipeda'
                  ? `${translation['academyInfo.label.city.klaipeda']}`
                  : location}
              </button>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

LocationDropdown.propTypes = {
  selectedCity: PropTypes.string.isRequired,
  dropdownRef: PropTypes.shape({
    current: PropTypes.shape(),
  }),
  academyLocations: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
  openDropdown: PropTypes.bool.isRequired,
  toggleChapterDropdown: PropTypes.func.isRequired,
  setSelectedCity: PropTypes.func.isRequired,
};

export { LocationDropdown };
