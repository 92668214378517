import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';

import './scss/styles.scss';
import { GlobalStateContext } from 'containers/GlobalContextProvider';
import { languageOptions } from 'lang';
import { contactInfo, getLocationsByCountry } from 'utilities';
import { Layout } from 'layout';
import { MediaLinks } from 'components/mediaLinks';
import { MediaGallery } from 'components/mediaGallery';
import { TestimonialsSlider } from 'components/testimonialsSlider';
import { FeaturedImages } from 'components/featuredImages';
import { SectionWithImage } from 'components/section';
import { Faq } from 'components/faq';
import {
  AcademySingleHero,
  AcademyHeroInfo,
  AcademyProcess,
  StudentsSchedule,
  ApplyFooter,
} from './components';

const AcademyStudentsSingle = ({ data }) => {
  const { markdownRemark } = data;
  const frontmatter = markdownRemark.frontmatter;
  const info = frontmatter.info;
  const faqSection = frontmatter.faqSection;
  const section = frontmatter.processSection;
  const scheduleSection = frontmatter.scheduleSection;

  const languageContext = useContext(GlobalStateContext);
  const translation = languageContext.dictionary.translations;
  if (markdownRemark.frontmatter.lang) {
    languageContext.setLanguage(
      languageOptions.find(
        (lang) => lang.id === markdownRemark.frontmatter.lang.toLowerCase()
      )
    );
  }

  const hasLanguageDropdown = Boolean(markdownRemark.frontmatter.lang);

  const contactsAndLocations = contactInfo()[0];
  const academyStudentsContacts = contactsAndLocations.frontmatter.contacts.find(
    (contact) => contact.academy === 'Students'
  );

  const renderAcademyInfo = () => (
    <AcademyHeroInfo
      location={info.location}
      duration={info.duration}
      tuition={info.tuition}
      tags={frontmatter.tags}
    />
  );

  const locationsArray = [
    ...getLocationsByCountry('Lithuania'),
    ...getLocationsByCountry('Poland'),
  ];

  return (
    <Layout
      darkTheme
      metaTitle={
        markdownRemark.frontmatter.metaInformation.metaTitle ||
        markdownRemark.frontmatter.title
      }
      metaDescription={
        markdownRemark.frontmatter.metaInformation.metaDescription || null
      }
      metaKeywords={
        markdownRemark.frontmatter.metaInformation.metaKeywords || null
      }
      metaImage={
        markdownRemark.frontmatter.metaInformation.metaImage
          ? markdownRemark.frontmatter.metaInformation.metaImage.children[0]
              .fixed.src
          : null
      }
      hasLanguageDropdown={hasLanguageDropdown}
    >
      <AcademySingleHero
        academyLocations={scheduleSection.locations}
        academyType={info.type}
        applicationDeadline={info.applicationDeadline}
        applicationLinks={frontmatter.applicationLinks}
        applicationStatusLabel={frontmatter.applicationStatusLabel}
        backgroundImage={
          frontmatter.heroBg && frontmatter.heroBg.children[0].fluid.src
        }
        title={frontmatter.title}
        shortDescription={frontmatter.shortDescription}
        type={info.type}
        renderRightSide={info && renderAcademyInfo}
      />
      <FeaturedImages images={markdownRemark.frontmatter.featuredImages} />
      <SectionWithImage
        className="students-about__section inverted"
        image={
          markdownRemark.frontmatter.about.aboutImage.image &&
          markdownRemark.frontmatter.about.aboutImage.image.children[0].fluid
        }
        imageText={markdownRemark.frontmatter.about.aboutImage.imageDescription}
        imageSide="right"
        title={markdownRemark.frontmatter.about.title}
        description={markdownRemark.frontmatter.about.description}
      />
      <AcademyProcess
        className="inverted"
        processes={section.processes}
        sectionTitle={section.title}
      />
      <StudentsSchedule
        className="inverted"
        lectures={scheduleSection.lectures}
        title={scheduleSection.title}
        description={scheduleSection.dropdownDescription}
        note={scheduleSection.note}
        upcomingAcademySchedule={scheduleSection.upcomingAcademySchedule}
        locations={scheduleSection.locations}
      />
      <MediaGallery
        title="Media"
        videoId={markdownRemark.frontmatter.mediaGallery.videoId}
        images={markdownRemark.frontmatter.mediaGallery.images}
        className="inverted"
      />
      <MediaLinks
        links={markdownRemark.frontmatter.mediaLinks}
        className="inverted"
      />
      <TestimonialsSlider
        testimonialsArray={markdownRemark.frontmatter.testimonials}
      />
      <Faq
        className="inverted"
        title={faqSection.title}
        otherQuestions={faqSection.otherQuestions}
        questions={faqSection.questions}
        questionsEmail={faqSection.questionsEmail}
      />
      <ApplyFooter
        title={frontmatter.title}
        applicationDeadline={info.applicationDeadline}
        academyLocations={scheduleSection.locations}
        academyType={info.type}
        contact={academyStudentsContacts}
        locations={locationsArray.length > 0 && locationsArray}
        applicationLinks={frontmatter.applicationLinks}
        trademark={translation['contacts.trademark']}
      />
    </Layout>
  );
};

AcademyStudentsSingle.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape(),
  }),
};

export const academyStudentsSingleQuery = graphql`
  query academyStudentsSingle($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      frontmatter {
        metaInformation {
          metaTitle
          metaDescription
          metaKeywords
          metaImage {
            children {
              ... on ImageSharp {
                fixed(width: 1080, height: 1080) {
                  src
                }
              }
            }
          }
        }
        lang
        title
        shortDescription
        heroBg {
          children {
            ... on ImageSharp {
              fluid(maxWidth: 1700, maxHeight: 1100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        tags
        about {
          title
          description
          aboutImage {
            image {
              children {
                ... on ImageSharp {
                  fluid(maxWidth: 799, maxHeight: 533) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
            imageDescription
          }
        }
        info {
          type
          startDate
          applicationDeadline
          admissionStartDate
          admissionEndDate
          duration
          location
          tuition
        }
        faqSection {
          title
          otherQuestions
          questionsEmail
          questions {
            questionText
            answer
          }
        }
        processSection {
          title
          processes {
            title
            description
            iconKeyword
          }
        }
        scheduleSection {
          title
          dropdownDescription
          note
          upcomingAcademySchedule {
            title
            link
          }
          locations
          lectures {
            title
            description
            kaunas {
              date
              time
              location
              duration
              lecturers {
                name
                link
              }
            }
            vilnius {
              date
              time
              location
              duration
              lecturers {
                name
                link
              }
            }
            klaipeda {
              date
              time
              location
              duration
              lecturers {
                name
                link
              }
            }
            warsaw {
              date
              time
              location
              duration
              lecturers {
                name
                link
              }
            }
          }
        }
        mediaLinks {
          url
          name
          source
        }
        mediaGallery {
          images {
            children {
              ... on ImageSharp {
                fluid(maxWidth: 799, maxHeight: 533, quality: 80) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          videoId
        }
        featuredImages {
          children {
            ... on ImageSharp {
              fluid(maxWidth: 900, maxHeight: 900, quality: 80) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        testimonials
        applicationLinks {
          kaunas
          vilnius
          klaipeda
          warsaw
        }
        applicationStatusLabel
      }
    }
  }
`;

export default AcademyStudentsSingle;
