import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';

import { GlobalStateContext } from 'containers/GlobalContextProvider';
import { HeroBannerLayout } from 'components/heroBanner';
import { getAcademyIcon } from 'utilities';
import { AcademyApplicationModal } from './AcademyApplicationModal';

const AcademySingleHero = ({
  academyLocations,
  academyType,
  applicationDeadline,
  applicationLinks,
  applicationStatusLabel,
  backgroundImage,
  title,
  shortDescription,
  type,
  renderRightSide,
}) => {
  const ACADEMY_FOR = 'Academy for';
  const [modalState, setModalState] = useState(false);

  const languageContext = useContext(GlobalStateContext);
  const translation = languageContext.dictionary.translations;

  return (
    <HeroBannerLayout
      className="hero-banner--featured-elements students-hero"
      backgroundImage={backgroundImage}
    >
      <div className="students-hero">
        <div className="students-hero__left-column">
          {title && (
            <div className="students-hero__title-wrapper">
              <figure className="students-hero__icon">
                <img src={getAcademyIcon(type)} alt={`Icon - ${title}`} />
              </figure>
              {languageContext.language.id === 'en' ? (
                <h1 className="students-hero__title">
                  {ACADEMY_FOR}
                  <br />
                  {title.split(ACADEMY_FOR)[1]}
                </h1>
              ) : (
                <h1 className="students-hero__title">{title}</h1>
              )}
            </div>
          )}
          {shortDescription && (
            <p className="students-hero__description">{shortDescription}</p>
          )}
          <button
            type="button"
            className="students-hero__button btn"
            onClick={() => setModalState(true)}
          >
            {translation['button.applyNow']}
          </button>
        </div>
        <div className="students-hero__right-column">{renderRightSide()}</div>
      </div>
      <AcademyApplicationModal
        academyLocations={academyLocations}
        academyTitle={title}
        academyType={academyType}
        applicationDeadline={applicationDeadline}
        applicationLinks={applicationLinks}
        modalTitle={
          applicationStatusLabel || translation['applicationModal.title']
        }
        status={modalState}
        setModalState={setModalState}
      />
    </HeroBannerLayout>
  );
};

AcademySingleHero.propTypes = {
  academyLocations: PropTypes.arrayOf(PropTypes.string.isRequired),
  academyType: PropTypes.string.isRequired,
  applicationDeadline: PropTypes.string,
  applicationLinks: PropTypes.shape({
    kaunas: PropTypes.string.isRequired,
    vilnius: PropTypes.string.isRequired,
  }),
  applicationStatusLabel: PropTypes.string,
  backgroundImage: PropTypes.string,
  title: PropTypes.string.isRequired,
  shortDescription: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  renderRightSide: PropTypes.func,
};

export { AcademySingleHero };
