import React, { useContext, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { CMSLink } from 'components/cmsLink';
import { Lecture } from 'components/lecture';
import { GlobalStateContext } from 'containers/GlobalContextProvider';
import { useCloseOnOutsideClick } from 'utilities';
import generateUniqueId from 'utilities/generateUniqueId';
import { LocationDropdown } from './LocationDropdown';

const StudentsSchedule = ({
  className,
  lectures,
  title,
  description,
  note,
  upcomingAcademySchedule,
  locations,
}) => {
  const dropdownRef = useRef(null);

  const languageContext = useContext(GlobalStateContext);
  const translation = languageContext.dictionary.translations;

  const [openDropdown, setOpenDropdown] = useState(false);
  const [selectedCity, setSelectedCity] = useState(locations[0]);
  const [openSchedule, setOpenSchedule] = useState(false);

  const toggleChapterDropdown = () => setOpenDropdown(!openDropdown);

  useCloseOnOutsideClick(dropdownRef, toggleChapterDropdown, openDropdown);

  const sortedArray = lectures.sort((a, b) => {
    const lectureCity = selectedCity.toLowerCase();
    if (!a[lectureCity]) {
      return 0;
    }

    const startDateA = new Date(a[lectureCity].date);
    const startDateB = new Date(b[lectureCity].date);

    if (startDateA < startDateB) {
      return -1;
    }
    if (startDateA > startDateB) {
      return 1;
    }
    return 0;
  });

  const renderLectures = (academyLocation, isSelected) => (
    <div
      className={classNames(
        'students-schedule__lectures',
        !isSelected && 'hidden'
      )}
      key={!isSelected && generateUniqueId()}
    >
      <h3 className="students-schedule__academy-location">{academyLocation}</h3>
      {sortedArray.map((lecture, index) => {
        const lectureData = lecture[academyLocation.toLowerCase()];

        return (
          lectureData && (
            <Lecture
              key={generateUniqueId()}
              className={classNames(index > 2 && !openSchedule && 'hidden')}
              date={lectureData.date}
              description={lecture.description}
              duration={lectureData.duration}
              lecturers={lectureData.lecturers}
              location={lectureData.location}
              title={lecture.title}
              time={lectureData.time}
            />
          )
        );
      })}
    </div>
  );

  const renderButton = () =>
    sortedArray &&
    sortedArray.length > 3 && (
      <button
        type="button"
        className={classNames(
          'btn btn--inverted students-schedule__lectures-button',
          { hidden: openSchedule }
        )}
        onClick={() => setOpenSchedule(true)}
      >
        {translation['button.showAll']}
      </button>
    );

  const renderNote = () =>
    note && (
      <p className="students-schedule__note">
        {note}
        {upcomingAcademySchedule &&
          upcomingAcademySchedule.title &&
          upcomingAcademySchedule.link && (
            <CMSLink
              className="students-schedule__link"
              linkUrl={upcomingAcademySchedule.link}
            >
              {` ${upcomingAcademySchedule.title}`}
            </CMSLink>
          )}
      </p>
    );

  return (
    <div
      className={classNames('students-schedule', className && `${className}`)}
    >
      <div className="students-schedule__frame">
        <h2 className="students-schedule__title">{title}</h2>
        <div className="students-schedule__dropdown-wrapper">
          <p className="students-schedule__dropdown-description">
            {description}
          </p>
          <LocationDropdown
            selectedCity={selectedCity}
            setSelectedCity={setSelectedCity}
            dropdownRef={dropdownRef}
            openDropdown={openDropdown}
            toggleChapterDropdown={toggleChapterDropdown}
            academyLocations={locations}
          />
        </div>
        {sortedArray &&
          sortedArray.length > 0 &&
          locations.map((academyLocation) => {
            return academyLocation === selectedCity
              ? renderLectures(academyLocation, true)
              : renderLectures(academyLocation, false);
          })}
        {renderButton()}
        {renderNote()}
      </div>
    </div>
  );
};

StudentsSchedule.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  className: PropTypes.string,
  note: PropTypes.string,
  upcomingAcademySchedule: PropTypes.shape({
    title: PropTypes.string,
    link: PropTypes.string,
  }),
  lectures: PropTypes.arrayOf(
    PropTypes.shape({
      description: PropTypes.string,
      title: PropTypes.string,
    })
  ),
  locations: PropTypes.arrayOf(PropTypes.string),
};

export { StudentsSchedule };
